<template>
  <div>
    <div class="mainWrapper clearfix">
      <div class="articleMeauleft">
        <a-divider orientation="left">题目分类</a-divider>
        <a-tree
          :replaceFields="replaceFields"
          :treeData="treeDataCategory"
          @select="this.onSelect"
        />
      </div>
      <div class="articleMainRight">
        <div class="pxkeji-right-box">
          <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="题目内容">
                  <a-input placeholder="请输入" v-model="search.question" />
                </a-form-item>
              </a-col>
              <a-col :span="4" :style="{ textAlign: 'left' }">
                <a-form-item>
                  <a-button type="primary" @click="searchList">搜索</a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <div>
            <pxkeji-btn
              name="添加"
              class="pxkeji_basic_btn"
              @click="showInfo('0')"
              type="primary"
              :roles="['题库-题目添加']"
            />
            <pxkeji-btn
              name="下载模板"
              @click="downloadTemp"
              class="pxkeji_basic_btn"
            />
            <a-upload
              name="file"
              :action="$api.subject.importSubject"
              accept=".xls, .xlsx"
              @change="handleChange"
              :showUploadList="false"
            >
              <a-button> <a-icon type="upload" />批量导入 </a-button>
            </a-upload>
          </div>
        </div>

        <div>
          <a-table
            rowKey="id"
            size="small"
            bordered
            :columns="list.columns"
            :pagination="list.pagination"
            @change="onPageChange"
            :dataSource="list.datas"
            :loading="isloading"
            :locale="{ emptyText: '暂无数据' }"
            :scroll="{ y: list.tableHeight }"
          >
            <template slot="subjectType" slot-scope="subjectType">
              <span v-if="subjectType == 0">单选题</span>
              <span v-if="subjectType == 1">多选题</span>
              <span v-if="subjectType == 2">判断题</span>
            </template>
            <template slot="questContent" slot-scope="questContent">
              <span :title="questContent" v-html="questContent"></span>
            </template>
            <template slot="nullify" slot-scope="nullify">
              <a-tag v-if="nullify == 1" color="red">是</a-tag>
              <a-tag v-else color="green">否</a-tag>
            </template>
            <span slot="action" slot-scope="info">
              <pxkeji-btn
                name="编辑"
                size="small"
                @click="showInfo(info.id)"
                type="primary"
                :roles="['题库-题目修改']"
              />
              <a-popconfirm
                title="是否确认删除此题目"
                @confirm="delInfo(info.id)"
              >
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn
                  name="删除"
                  :roles="['题库-题目删除']"
                  size="small"
                  type="danger"
                />
              </a-popconfirm>

              <a-popconfirm
                title="是否确认作废此题目"
                @confirm="quitInfo(info.id)"
              >
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn
                  name="作废"
                  :roles="['题库-题目作废']"
                  size="small"
                  type="danger"
                  v-if="info.nullify == 0"
                />
              </a-popconfirm>
              <a-popconfirm
                title="是否确认启用此题目"
                @confirm="startInfo(info.id)"
              >
                <a-icon slot="icon" type="question-circle-o" />
                <pxkeji-btn
                  name="启用"
                  :roles="['题库-题目作废']"
                  size="small"
                  color="#87d068"
                  v-if="info.nullify == 1"
                />
              </a-popconfirm>
            </span>
          </a-table>
        </div>
      </div>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <Info @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import Info from "./subjectInfo.vue"; //编辑

const columns = [
  {
    dataIndex: "categoryName",
    align: "center",
    width: 180,
    title: "分类",
    ellipsis: true,
  },
  {
    dataIndex: "subjectType",
    align: "center",
    title: "题目类型",
    width: 90,
    scopedSlots: { customRender: "subjectType" },
  },
  {
    dataIndex: "difficultName",
    align: "center",
    title: "难度等级",
    width: 90,
  },
  {
    dataIndex: "questContent",
    align: "center",
    title: "题目内容",
    ellipsis: true,
    scopedSlots: { customRender: "questContent" },
  },
  {
    dataIndex: "nullify",
    align: "center",
    title: "是否作废",
    width: 80,
    scopedSlots: { customRender: "nullify" },
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let excelLoading = "";
let _this;
export default {
  name: "subjectlist",
  data() {
    return {
      nullify: "",
      treeDataCategory: [],
      replaceFields: { children: "list", title: "name", key: "id" },
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 292,
      },
      search: {
        //搜索条件
        question: "",
        categoryId: [],
      },
      isloading: false,
      addedit: {
        title: "",
        visible: false,
        info: {},
      },
    };
  },
  components: {
    Info,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
    _this.getTree();
  },
  methods: {
    onSelect(selectedKeys) {
      _this.search.categoryId = selectedKeys;
      _this.GetList();
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.subject
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.question,
          _this.search.categoryId.join()
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
            res.data.records.forEach((item) => {
              if (item.optionConf) {
                let opArr = [];
                for (let i in JSON.parse(item.optionConf)) {
                  opArr.push(i + ":" + JSON.parse(item.optionConf)[i]);
                }
                item.optionConf = opArr.join(" ");
              }
            });
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    getTree() {
      _this.$api.questioncategory
        .getTree()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            res.data.forEach((item) => {
              item.id = Number(item.id);
            });
            _this.treeDataCategory = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {});
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    //编辑
    showInfo(id) {
      _this.addedit.title = id ? "编辑" : "添加";
      _this.addedit.info.id = id;
      _this.addedit.info.categoryId = _this.search.categoryId[0];
      _this.addedit.visible = true;
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
    delInfo(id) {
      _this.isloading = true;
      _this.$api.subject
        .removeById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    quitInfo(id) {
      // 作废
      _this.isloading = true;
      _this.$api.subject
        .nullify(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    startInfo(id) {
      // 启用
      _this.isloading = true;
      _this.$api.subject
        .valid(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "application/vnd.ms-excel") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传xls/xlsx格式的文件");
      }
      const isLt200M = file.size / 1024 / 1024 < 200;
      if (!isLt200M) {
        this.$message.error("文件不能超过200MB");
      }
      return isJPGPNG && isLt200M;
    },
    handleChange(info) {
      if (info.file.status == "uploading") {
        if (excelLoading == "")
          excelLoading = _this.$message.loading("上传中", 0);
      } else if (info.file.status == "done") {
        excelLoading();
        excelLoading = "";
        if (info.file.response.errorCode == _this.$msg.responseCode.success) {
          _this.$message.success(info.file.response.errorMsg);
          _this.GetList();
          _this.getTree();
        } else {
          _this.$message.error(info.file.response.errorMsg);
        }
      }
    },
    downloadTemp() {
      window.location.href = _this.$api.subject.downloadTemp();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}

.mainWrapper {
  box-sizing: border-box !important;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 100px;
  background: #fff;
  float: left;
  margin-top: 10px;
}
.articleMainRight {
  width: 80%;
  float: right;
}
.ant-table-tbody p {
  margin: 0;
}
</style>

