<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="subjectForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="题目分类" prop="categoryIds">
          <a-cascader
            placeholder="请选择"
            changeOnSelect
            allowClear
            :options="categoryTreeData"
            :fieldNames="{ label: 'name', value: 'id', children: 'list' }"
            v-model="info.categoryIds"
          />
        </a-form-model-item>
        <a-form-model-item label="题目类型" prop="subjectType">
          <a-select placeholder="请选择" v-model="info.subjectType">
            <a-select-option
              v-for="(info, index) in subjectTypeList"
              :value="info.value"
              :key="index"
            >
              {{ info.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="难度等级" prop="difficult">
          <a-select placeholder="请选择" v-model="info.difficult">
            <a-select-option
              v-for="(info, index) in difficultList"
              :value="info.id"
              :key="index"
            >
              {{ info.itemName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="题目内容" prop="questContent">
          <vue-ueditor-wrap
            v-model="info.questContent"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item
          v-if="info.subjectType == 2"
          label="判断题答案"
          prop="answer"
        >
          <a-radio-group v-model="info.answer" :options="judgeOption" />
        </a-form-model-item>
        <a-form-model-item v-else label="题目选项" required>
          <a-row v-for="(item, index) in answerList" :key="index">
            <a-col :span="2">
              <div v-if="index >= 2 && index == answerList.length - 1">
                <a-button
                  size="small"
                  type="link"
                  icon="plus"
                  @click="addAns()"
                ></a-button>
                <a-button
                  size="small"
                  type="link"
                  icon="minus"
                  @click="redAns(index)"
                  v-if="index >= 3"
                ></a-button>
              </div>
            </a-col>
            <a-col :span="1" style="text-align: center">
              <span>{{ item.title }}</span>
            </a-col>
            <a-col :span="15">
              <a-input
                v-model.trim="item.answer"
                @change="onAnschange($event, index)"
              ></a-input>
            </a-col>
            <a-col :span="5" :offset="1">
              <a-radio
                v-if="info.subjectType == 0"
                :checked="item.isCheck"
                @change="onRightchange($event, index)"
                >设置为正确答案</a-radio
              >
              <a-checkbox
                v-if="info.subjectType == 1"
                :checked="item.isCheck"
                @change="onCheckChange($event, index)"
                >设置为正确答案</a-checkbox
              >
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="题目解析">
          <a-textarea
            placeholder="请输入"
            v-model="info.answerExplain"
            :rows="4"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            @click="cancelClick"
            style="margin-left: 10px"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
import VueUeditorWrap from "vue-ueditor-wrap"; // ES6 Module

export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      categoryTreeData: [],
      info: {
        subjectType: 0,
        difficult: "",
        answer: "",
      },
      rules: {
        categoryIds: [
          {
            type: "array",
            required: true,
            message: "请选择题目分类",
            trigger: "change",
          },
        ],
        subjectType: [
          {
            required: true,
            message: "请选择题目类型",
            trigger: "change",
          },
        ],
        difficult: [
          {
            required: true,
            message: "请选择难度等级",
            trigger: "change",
          },
        ],
        questContent: [
          {
            required: true,
            message: "请输入题目内容",
            trigger: "change",
          },
        ],
        answer: [
          {
            required: true,
            message: "请设置答案",
            trigger: "change",
          },
        ],
      },
      subjectTypeList: [
        { name: "单选", value: 0 },
        { name: "多选", value: 1 },
        { name: "判断", value: 2 },
      ],
      difficultList: [
      ],
      judgeOption: [
        { label: "正确", value: "正确" },
        { label: "错误", value: "错误" },
      ],
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 180,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
      answerList: [
        {
          title: "A",
          answer: "",
          isCheck: false,
        },
        {
          title: "B",
          answer: "",
          isCheck: false,
        },
        {
          title: "C",
          answer: "",
          isCheck: false,
        },
        {
          title: "D",
          answer: "",
          isCheck: false,
        },
      ],
    };
  },
  components: {
    VueUeditorWrap,
  },
  mounted() {
    _this = this;
    _this.getTree();
    _this.getDifficultTypeList()
    if (_this.infos.id != "0") {
      _this.getInfo();
    } else if (_this.infos.categoryId) {
      _this.getParentChain(_this.infos.categoryId);
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    //题目分类
    async getTree() {
      let res = await _this.$api.questioncategory.getTree();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.categoryTreeData = res.data;
      }
    },
    //题目分类
    async getDifficultTypeList() {
      let res = await _this.$api.dictionaryItem.tree(44);
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.difficultList = res.data;
      }
    },
    addAns() {
      _this.answerList.push({
        title: String.fromCharCode(_this.answerList.length + 65),
        answer: "",
        isCheck: false,
      });
    },
    redAns(index) {
      _this.answerList.splice(index, 1);
    },
    //选项内容输入
    onAnschange(e, index) {
      _this.answerList[index].answer = e.target.value;
    },
    onRightchange(e, index) {
      _this.answerList.forEach((item) => {
        item.isCheck = false;
      });
      _this.answerList[index].isCheck = e.target.checked;
      console.log(_this.answerList);
    },
    onCheckChange(e, index) {
      _this.answerList[index].isCheck = e.target.checked;
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.subject
        .getDetail(_this.infos.id)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.info = { ...res.data };
            let optionConf = JSON.parse(res.data.optionConf);
            if (res.data.subjectType != 2) {
              let index = 0;
              for (const key in optionConf) {
                let isCheck = false;
                if (
                  res.data.answer.split("-&-").indexOf(optionConf[key]) != -1
                ) {
                  isCheck = true;
                }
                _this.$set(_this.answerList, index, {
                  title: key,
                  answer: optionConf[key],
                  isCheck: isCheck,
                });
                index++;
              }
            }
            _this.getParentChain(res.data.categoryId);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**回显栏目 */
    getParentChain(id) {
      _this.$api.questioncategory
        .getParentIds(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$set(_this.info, "categoryIds", res.data);
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.subjectForm.validate((valid) => {
        if (valid) {
          if (_this.info.subjectType != 2) {
            let missOption = [];
            let setAnswer = false;
            _this.answerList.forEach((item) => {
              if (item.answer == "") {
                missOption.push(item.title);
              }
              if (item.isCheck) {
                setAnswer = true;
              }
            });
            if (missOption.length > 0) {
              _this.$message.error(
                `请输入选项 ${missOption.join(", ")} 的答案内容`
              );
              return false;
            }
            if (!setAnswer) {
              _this.$message.error("请设置答案");
              return false;
            }
          }

          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = { ..._this.info };
          formData.categoryId =
            formData.categoryIds[formData.categoryIds.length - 1];
          let optionConf = {};
          if (_this.info.subjectType != 2) {
            formData.answer = _this.answerList
              .filter((item) => item.isCheck)
              .map((item) => item.answer)
              .join("-&-");
            _this.answerList.forEach((item) => {
              optionConf[item.title] = item.answer;
            });
          } else {
            _this.judgeOption.forEach((item, index) => {
              optionConf[String.fromCharCode(index + 65)] = item.value;
            });
          }
          formData.optionConf = JSON.stringify(optionConf);
          delete formData.categoryIds;
          if (_this.infos.id != "0") {
            formData.id = _this.infos.id;
          }
          //添加
          _this.$api.subject
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.ant-cascader-menu {
  height: 320px;
}
</style>